<template>
    <v-card :color="color" :height="height" :width="width" v-show="loaded" class="rounded-0 pa-1 ma-0 d-flex flex-column" :to="goTo" :hover="goTo != null"> 
        <v-row class="pa-0 ma-0">
                <span class="white--text font-weight-medium mx-4 my-2" style="font-size: 0.9rem">
                    {{ title }}
                </span>
        </v-row>
        <v-row class="pa-0 ma-0">
            <v-col class="d-flex justify-center align-center col-4 pa-0">
                <v-icon color="white" size="50">{{ icon }}</v-icon>
            </v-col>
            <v-col class="d-flex flex-column justify-center col-8 pa-0">
                <div class="text-right text-no-wrap text-truncate white--text mx-4">
                    <slot></slot>
                </div>
                <div class="text-right text-no-wrap text-truncate white--text mb-2 mx-4">
                    <span class="text-body-2">
                        {{ text }} 
                    </span>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<style scoped>
    .inln {
        display: inline-block;
    }
</style>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },

        icon: {
            type: String,
            default: null
        },

        height: {
            type: String,
            default: null,
        },

        width: {
            type: String,
            default: null,
        },

        color: {
            type: String,
            default: null,
        },

        loaded:{
            type: Boolean,
            default: true
        },

        text:{
            type: String,
            default: null
        },

        goTo:{
            type: Object,
            default: null
        }
    }
};
</script>
