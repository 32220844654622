<template>
    <Page icon="mdi-poll" :title="$t('routes.dashboard')" :progress="progress" :error="error" @reload="load()">
        <v-row>
            <v-col v-for="c in cards" :key="c.index" class="d-flex col-12 col-sm-6 col-lg-3 pa-3">
                <InfoCard height="100%" width="100%" :icon="c.icon" :color="c.color" :title="c.title" :text="c.text" :goTo="c.goTo"> 
                    <span :class="c.dataClass">
                        {{ c.data }} 
                    </span>
                </InfoCard>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12 col-md-6 pa-3">
                <Card :title="$t('dashboard.week_events.title')" height="100%" ref="weekEvents">
                    <v-row v-if="weekEventsData && weekEventsData.length > 0" dense>
                        <v-col
                            v-for="(event, i) in weekEventsData"
                            :key="i"
                            cols="12"
                        >
                            <v-hover>
                                <template v-slot:default="{ hover }">
                            <v-card
                                :color="(new Date(event.start).toLocaleDateString() == new Date(Date.now()).toLocaleDateString() ?
                                            '#347AB8' : (new Date(event.start).toLocaleDateString() == new Date(Date.now() + 24*60*60*1000).toLocaleDateString() ?
                                            '#255885' : 'blue-grey darken-1'))"
                                :to="event.call ? { name: 'call', params: { id: event.call._id, routefrom: 'calls' } } : null"
                                dark
                                class="px-4 py-1 rounded-lg elevation-0"
                                :style="hover && event.call ? 'opacity:0.92' : ''"
                                
                            >
                                <div class="d-flex flex-no-wrap justify-space-between text-no-wrap text-truncate">
                                    <div class="d-flex flex-column flex-no-wrap justify-center text-no-wrap text-truncate">
                                        <span
                                            class="text-subtitle-1 font-weight-regular text-no-wrap text-truncate"
                                        >{{event.name ? event.name : $t('dashboard.week_events.patient') + ": " + event.call.patient.name}}</span>

                                        <span class="text-body-2 font-weight-light text-no-wrap text-truncate">
                                            <span class="text-body-2 blue-grey--text text--lighten-4 font-weight-regular text-no-wrap text-truncate">
                                                {{
                                                    (new Date(event.start).toLocaleDateString() == new Date(Date.now()).toLocaleDateString() ?
                                                    $t('dashboard.week_events.today') : (new Date(event.start).toLocaleDateString() == new Date(Date.now() + 24*60*60*1000).toLocaleDateString() ?
                                                    $t('dashboard.week_events.tomorrow') : new Date(event.start).toLocaleDateString() + ","))
                                                }}
                                            </span>
                                            <span class="text-body-2 font-weight-regular text-no-wrap text-truncate">
                                                {{new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' })}}
                                            </span>
                                        </span>
                                    </div>

                                    <div class="d-flex align-center">
                                        <span class="text-subtitle-1 font-weight-light text-no-wrap text-truncate ml-3">
                                            <HumanTime 
                                                :time="new Date(event.start).getTime() - Math.floor(Date.now()/60000)*60000"
                                                :units="new Date(event.start).toLocaleDateString() == new Date(Date.now()).toLocaleDateString() || new Date(event.start).toLocaleDateString() == new Date(Date.now() + 24*60*60*1000).toLocaleDateString() ?
                                                    ['d', 'h', 'm'] : ['d']"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </v-card>
                                </template>
                            </v-hover>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col class="d-flex d-column align-center justify-center">
                            <v-container fluid class="pa-4 d-flex align-center justify-center">
                                <div class="text-center">
                                    <v-icon size="60" class="mb-2">mdi-calendar-remove-outline</v-icon>

                                    <div class="text-h6 font-weight-regular">{{ $t('dashboard.week_events.no_events') }}</div>

                                    <v-btn class="mt-3" color="primary" :to="{ name: 'calendar' }">
                                        <v-icon left>mdi-calendar</v-icon>{{ $t('routes.calendar') }}
                                        </v-btn>
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>
                </Card>
            </v-col>

            <v-col class="col-12 col-md-6 pa-3">
                <Card :title="$t('dashboard.login_history.title')" height="100%">
                    <v-card flat :height="$vuetify.breakpoint.mdAndUp ? Math.max(tableHeight - 20, 145) : 312" class="d-flex rounded-0 pb-2">
                    <v-container class="d-flex flex-column ma-0 pa-0" fluid>
                    <v-simple-table fixed-header class="d-flex flex-column mt-2 rounded-0" style="overflow-y: hidden" min-height="125" dense>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="primary--text text-left"> {{ $t('dashboard.login_history.list.action') }} </th>
                                <th class="primary--text text-center"> {{ $t('dashboard.login_history.list.ip_address') }} </th>
                                <th class="primary--text text-center"> {{ $t('dashboard.login_history.list.time') }} </th>
                            </tr>
                        </thead>
                        <tbody style="overflow-y: scroll">
                            <tr v-for="e in loginHistoryData" :key="e.index">
                                <td class="text-left"> 
                                    <span v-if="e.success" class="green--text text--darken-2"> {{ $t('dashboard.login_history.successful_login') }} </span> 
                                    <span v-else class="red--text text--darken-2"> {{ $t('dashboard.login_history.failed_login') }} </span> 
                                </td>
                                <td class="text-center"> {{ e.ip }} </td>
                                <td class="text-center"> 
                                    <span class="font-weight-bold"> {{ new Date(e.time).toLocaleDateString() }} </span> 
                                    <span class="font-weight-regular"> {{ new Date(e.time).toLocaleTimeString() }} </span> 
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    </v-container>
                    </v-card>
                </Card>
            </v-col>
        </v-row>
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import Card from "./components/Card.vue";
import HumanTime from "./components/HumanTime.vue";
import InfoCard from "./components/InfoCard.vue";

export default {
    components: {
        Page,
        Card,
        HumanTime,
        InfoCard
    },

    async mounted() {
        this.load();
        
        this.interval = setInterval(this.load, 5000);
    },

    beforeDestroy(){
        clearInterval(this.interval);
    },

    updated() {
        if(this.$refs.weekEvents && this.$refs.weekEvents.$el) this.tableHeight = this.$refs.weekEvents.$el.children[1].clientHeight;
    },

    data: () => ({
        progress: true,
        interval: null,

        form: {
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        entity: null,

        loginHistoryData: null,
        weekEventsData: null,

        cards: [],
        colors: ["#E65E5E", "#347AB8", "#546E7A", "primary"],
        tableHeight: 0
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }
            
            this.loginHistoryData = this.user.login_history;
            if (this.loginHistoryData) {
                this.loginHistoryData.sort((a,b) => (a.time > b.time ? -1 : 1))
                this.loginHistoryData = this.loginHistoryData.slice(0,20)
            }

            this.selectedEntity();

            const res = await this.$store.dispatch("api/dashboard/stats", this.form);
            if (res.status == 200) {
                this.cards = [];

                if (res.data.helprequests != null && this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.helprequests.title'),
                        data: res.data.helprequests,
                        text: this.$t('dashboard.tiles.helprequests.text'),
                        icon: "mdi-lifebuoy",
                        dataClass: "text-h4 font-weight-regular",
                        goTo: { name: 'helprequests' }
                    });
                }

                if (res.data.tests != null && this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.balance.title'),
                        data: res.data.tests,
                        text: this.$t('dashboard.tiles.balance.text'),
                        icon: "mdi-walk",
                        dataClass: "text-h4 font-weight-regular",
                        goTo: { name: 'patients', params: { not_ranked: true } }
                    });
                }

                if (res.data.calls != null && this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.calls.title'),
                        data: res.data.calls,
                        text: this.$t('dashboard.tiles.calls.text'),
                        icon: "mdi-video-wireless-outline",
                        dataClass: "text-h4 font-weight-regular",
                        goTo: { name: 'calls' }
                    });
                }

                if (this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.today_events.title'),
                        data: (res.data.today_events ? res.data.today_events.length : 0),
                        text: this.$t('dashboard.tiles.today_events.text'),
                        icon: "mdi-calendar-alert",
                        dataClass: "text-h4 font-weight-regular",
                        goTo: { name: 'calendar' }
                    });
                }

                if (this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.tomorrow_events.title'),
                        data: (res.data.tomorrow_events ? res.data.tomorrow_events.length : 0),
                        text: this.$t('dashboard.tiles.tomorrow_events.text'),
                        icon: "mdi-calendar-arrow-right",
                        dataClass: "text-h4 font-weight-regular",
                        goTo: { name: 'calendar' }
                    });
                }

                if (this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.entity.title'),
                        data: this.user.entity.name,
                        text: this.user.entity.company.name,
                        icon: "mdi-home-city-outline",
                        dataClass: "text-h6 font-weight-regular"
                    });
                }

                if (this.cards.length < 4) {
                    this.cards.push({
                        title: this.$t('dashboard.tiles.last_login.title'),
                        data: ((this.user.login_history && this.user.login_history.length > 0) ? new Date(this.user.login_history[0].time).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) : "-"),
                        text: ((this.user.login_history && this.user.login_history.length > 0) ? new Date(this.user.login_history[0].time).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) : "-"),
                        icon: "mdi-account-clock",
                        dataClass: "text-h4 font-weight-regular"
                    });
                }

                for (let i = 0; i < 4; i++) {
                    this.cards[i].color = this.colors[i];
                }

                this.weekEventsData = [];
                if (res.data.today_events && res.data.today_events.length > 0) this.weekEventsData = this.weekEventsData.concat(res.data.today_events);
                if (res.data.tomorrow_events && res.data.tomorrow_events.length > 0) this.weekEventsData = this.weekEventsData.concat(res.data.tomorrow_events);
                if (res.data.after_tomorrow_events && res.data.after_tomorrow_events.length > 0) this.weekEventsData = this.weekEventsData.concat(res.data.after_tomorrow_events);

                if (this.weekEventsData.length > 0) {
                    this.weekEventsData.sort((a,b) => (a.start > b.start ? 1 : -1))
                    this.weekEventsData = this.weekEventsData.slice(0,5)
                }

                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        },
    }
};
</script>
